/**
 * toggleClass 対象DOMの指定クラスのスイッチング
 *
 * @param  {HTMLElement|Element|null} dom          DOM
 * @param  {String}              target_class 対象クラス名
 */
export function toggleClass(dom, target_class) {
    if (dom === null)
        return;
    dom.classList.toggle(target_class);
}
/**
 * addClass 対象に指定のクラスを追加
 *
 * @param  {HTMLElement|Element|null} dom          DOM
 * @param  {String}              target_class 対象クラス名
 */
export function addClass(dom, target_class) {
    if (dom === null)
        return;
    dom.classList.add(target_class);
}
/**
 * removeClass 対象から指定のクラスを削除
 *
 * @param  {HTMLElement|Element|null} dom          DOM
 * @param  {String}              target_class 対象クラス名
 */
export function removeClass(dom, target_class) {
    if (dom === null)
        return;
    dom.classList.remove(target_class);
}
/**
 * isClassContain 対象にクラスが存在するか確認
 *
 * @param  {HTMLElement|Element|null} dom          DOM
 * @param  {String}              target_class 対象クラス名
 * @return {Boolean}                          クラス存在の有無
 */
export function isClassContain(dom, target_class) {
    if (dom === null)
        return false;
    return dom.classList.contains(target_class);
}
/**
 * 文字列の簡易な無害化を実行します
 * https://qiita.com/saekis/items/c2b41cd8940923863791
 *
 * @param  {string} text エスケープ処理が必要な文字列
 * @return {string}      処理後文字列
 */
export function escapeString(text) {
    return text.replace(/[&'`"<>]/g, function (ltr) {
        switch (ltr) {
            case '&': return '&amp;';
            case "'": return '&#x27;';
            case '`': return '&#x60;';
            case '"': return '&quot;';
            case '<': return '&lt;';
            case '>': return '&gt;';
        }
        return ltr;
    });
}
