//
//  for front-page.php
//
import * as Helper from '../helper';
/**
 * showPrefectures 指定地域の都道府県リストを表示する
 *
 * @param  {String} wrapper_class 表示したい要素を包んでいる要素クラス名
 * @param  {String} target_class  対象要素のクラス名
 */
export function showPrefectures(wrapper_class, area_table_class) {
    if (wrapper_class === '' ||
        area_table_class === '') {
        throw new Error('front-page/showPrefectures: invalid arguments');
    }
    var target_class = wrapper_class + ', ' + area_table_class;
    var target_el = document.querySelectorAll(target_class);
    target_el.forEach(function (dom) {
        Helper.addClass(dom, 'is_active');
    });
}
/**
 * hiddenPrefectures 表示中の都道府県リストを非表示にする
 *
 * @param  {String} target 対象DOMのセレクタ
 */
export function hidePrefectures(wrapper_class) {
    if (wrapper_class === '')
        throw new Error('front-page/hidePrefectures: invalid argument');
    var target_class = wrapper_class + ', ' + wrapper_class + ' .is_active';
    var target_dom_list = document.querySelectorAll(target_class);
    target_dom_list.forEach(function (dom) {
        Helper.removeClass(dom, 'is_active');
    });
}
